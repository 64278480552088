import React from 'react'

import Main from '../layout/Main'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Main>
    <SEO title="404: Not found" />
    <div style={{textAlign: 'center'}}>
      <h1>You just got the shaft!</h1>
      <p>This page doesn't exist. Don't be sad though, just go to the <a href="/">homepage</a>.</p>
    </div>
  </Main>
)

export default NotFoundPage
